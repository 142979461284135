import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import { table, labelCol, state, happy, angry, bored, disappointed, wasAngry } from "./freshness-table.module.css"

const Happy = () => <td className={clsx(state, happy)}>😀</td>

const Bored = () => <td className={clsx(state, bored)}>😴</td>

const Angry = () => <td className={clsx(state, angry)}>😡</td>

const BoredWasAngry = () => <td className={clsx(state, bored, wasAngry)}>😴</td>

const DisappointedWasAngry = () => <td className={clsx(state, disappointed, wasAngry)}>😐</td>

const FreshnessTable = ({ isImproved }) => (
  <table className={table}>
    <tbody>
      <tr>
        <th rowSpan={2} colSpan={2}></th>
        <th colSpan={4}>Content state</th>
      </tr>
      <tr>
        <td>Fresh</td>
        <td>SWR</td>
        <td>SIE</td>
        <td>None</td>
      </tr>
      <tr>
        <th rowSpan={4} className={labelCol}>
          Origin
          <br />
          state
        </th>
        <td className={labelCol}>Healthy</td>
        <Happy />
        <Happy />
        <Bored />
        <Bored />
      </tr>
      <tr>
        <td className={labelCol}>Erroring</td>
        <Happy />
        <Happy />
        {isImproved ? <BoredWasAngry /> : <Angry />}
        {isImproved ? <DisappointedWasAngry /> : <Angry />}
      </tr>
      <tr>
        <td className={labelCol}>Down</td>
        <Happy />
        <Happy />
        {isImproved ? <BoredWasAngry /> : <Angry />}
        {isImproved ? <DisappointedWasAngry /> : <Angry />}
      </tr>
      <tr>
        <td className={labelCol}>Sick</td>
        <Happy />
        <Happy />
        <Happy />
        {isImproved ? <DisappointedWasAngry /> : <Angry />}
      </tr>
    </tbody>
  </table>
)

FreshnessTable.defaultProps = {
  isImproved: false,
}

FreshnessTable.propTypes = {
  isImproved: PropTypes.bool,
}

export default FreshnessTable
